import { Component } from "@angular/core";

@Component({
    selector: 'app-webinar-sign-up',
    templateUrl: './webinar-sign-up.component.html',
    styleUrls: ['./webinar-sign-up.component.scss']
})
export class WebinarSignUpComponent {
    sessions = [
        {
            date: "Tuesday, 2/25 3pm EST",
            link: "https://events.teams.microsoft.com/event/ab630d3a-b270-46e5-a5a2-1a3d04937486@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Thursday, 2/27 4pm EST",
            link: "https://events.teams.microsoft.com/event/f8b9b16b-bf67-4f32-b677-fcf944a80f2e@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Wednesday, 3/12 4pm EST",
            link: "https://events.teams.microsoft.com/event/e90a54d5-4d7f-443d-ad71-7cabb5c29bb2@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Thursday, 3/20 3pm EST",
            link: "https://events.teams.microsoft.com/event/9a0b76fd-5c75-44f0-ab0d-282aa7ad2a84@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        }
    ];

    constructor() {}

}
